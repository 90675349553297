import * as signalR from '@aspnet/signalr'
const mode = process.env.NODE_ENV
let logLevel
if (mode === "production") {
    logLevel = signalR.LogLevel.Error
} else {
    logLevel = signalR.LogLevel.Trace
}
export const connection = new signalR.HubConnectionBuilder().withUrl('/updaters', {

    logMessageContent: true,
    // logger: signalR.LogLevel.Debug,
    useDefaultCredentials: true
})
    .configureLogging(logLevel)
    .build()

