
import { CONNECTION_SET, CONNECTION_REQUESTED, DISCONNECTED, RECENTLY_SIGNED_WAIVERS_FETCHED, PENDING_WAIVERS_FETCHED, SIGNALR_COMPANY, RECONNECTING_SIGNALR, RECONNECT_SIGNALR } from '../constants';
import { isEmpty, map, unnest, find, propEq, isNil, findIndex, not, clone, append, merge, has, equals, contains } from 'ramda';
import { connection } from '../lib/connection'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'

export const disconnect = (conn) => async (dispatch, getState) => {
    // dispatch({ type: RECONNECT_SIGNALR })
    console.log("disconnecting")
    if (conn) {
        conn.stop()

    } else {
        dispatch({ type: DISCONNECTED })
    }


}


export const setConnection = async (dispatch, getState) => {
    dispatch({ type: CONNECTION_REQUESTED })
    dispatch({ type: CONNECTION_SET, payload: connection })
    const companyId = getState().authentication.CurrentCompanyId

    //this updates every incoming status and updates the list color as well -lym
    connection.on("updatesignedwaiverstatus", (swId, status) => {
        const text = `Group ${companyId}: update ${swId} to Status: ${status}`
        let color
        if (status === 1) {
            color = green[200]
        } else if (status === -1) {
            color = red[200]
        }
        console.log("connection", text)
        const swObj = { SignedWaiver_Id: Number(swId), Status: Number(status), color: color }
        dispatch(currentSW(swObj))


    })
    connection.on("updatenote", (swId, note) => {
        const text = `Group ${companyId}: update ${swId} note: ${note}`
        console.log("connection", text)
        const swObj = { SignedWaiver_Id: Number(swId), note: note }
        dispatch(currentSW(swObj))

    })
    connection.on("newsignedwaiver", sw => {

        console.log("connection: new SignedWaiver", sw.SignedWaiver_Id)
        dispatch(applyNewSW(sw))

    })
    function invoker() {
        connection.invoke("MonitorCompany", getState().authentication.CurrentCompanyId)
            .catch(err => {
                console.error(err.toString())
                setTimeout(() => {
                    dispatch({ type: CONNECTION_SET, payload: connection })
                    dispatch(setConnection)
                }, 5000)
            })
            .then(() => dispatch({ type: SIGNALR_COMPANY, payload: getState().currentCompany.id }))
    }
    async function starter() {
        //this prevents reconnecting error on state that is not disconnected
        if (connection.connectionState && connection.connectionState === 1) {
            invoker()
        } else {
            await connection.start()
                .then(() => {
                    dispatch({ type: CONNECTION_SET, payload: connection })

                    invoker()
                    // connection.invoke("MonitorCompany", getState().authentication.CurrentCompanyId)
                    //     .catch(err => {
                    //         console.error(err.toString())
                    //         setTimeout(() => {
                    //             dispatch({ type: CONNECTION_SET, payload: connection })
                    //             dispatch(setConnection)
                    //         }, 5000)
                    //     })
                    //     .then(() => dispatch({ type: SIGNALR_COMPANY, payload: getState().currentCompany.id }))
                })
                .catch(err => {
                    setTimeout(() => {
                        console.error("Connection Error: ", err)
                        if (connection.connectionState !== 1) {
                            dispatch({ type: CONNECTION_SET, payload: connection })
                            starter()
                        }
                    }, 5000)
                })
        }
    }

    starter()

    connection.onclose((e) => {
        //  console.log("onClose", e)
        setTimeout(async function () {
            if (getState().signalR.reconnectSignalR && localStorage.isAuth) {
                console.warn("Trying reconnect to hub.");
                dispatch({ type: RECONNECTING_SIGNALR })
                dispatch({ type: CONNECTION_SET, payload: connection })
                if (connection.connectionState === 0) {
                    // dispatch(setConnection)
                    await starter()
                }
                // dispatch(startConnection())
            }
            //startSignalRConnection(connection);
        }, 5000)
    });
}


const currentSW = swObj => (dispatch, getState) => {
    const id = swObj.SignedWaiver_Id
    const pending = getState().pendingSW
    //  const recent = getState().recentSW
    const sws = map(s => s.SignedWaivers)(pending)

    const allsws = unnest(sws)
    const sw = find(propEq("SignedWaiver_Id", id), allsws)
    const hasNote = has("note")
    if (!isNil(sw)) {
        if (hasNote(swObj)) {
            const nn = { Version: swObj.note.Version, Id: swObj.note.Id }
            const cn = map(n => ({ Version: n.Version, Id: n.Id }), sw.CurrentNotes)
            if (!contains(nn, cn)) {
                if (swObj.note.Version === 1) {

                    const newCurrentNotes = append(swObj.note, sw.CurrentNotes)
                    //  const newNotes = append(swObj.note, sw.Notes)
                    const newSWObj = { SignedWaiver_Id: swObj.SignedWaiver_Id, CurrentNotes: newCurrentNotes }
                    const newsw = merge(sw, newSWObj)
                    dispatch(updateSignedWaivers(newsw))
                    //            dispatch(updateSignedWaivers(newsw))
                    // console.log(newsw)
                    // if (equals(swObj.SignedWaiver_Id, getState().signedWaiver.SignedWaiver_Id)) {
                    //     dispatch({ type: UPDATE_NOTES, payload: newCurrentNotes })
                    //     dispatch(updateSignedWaivers(newsw))
                    // } else {

                    // }
                } else {
                    //   const currentNote = find(propEq("Id", swObj.note.Id), sw.CurrentNotes)
                    const ni = findIndex(propEq("Id", swObj.note.Id), sw.CurrentNotes)
                    const nsw = clone(sw)
                    const cns = nsw.CurrentNotes
                    const newCurrentNotes = Object.assign([], cns, { [ni]: swObj.note })
                    const newSWObj = { SignedWaiver_Id: swObj.SignedWaiver_Id, CurrentNotes: newCurrentNotes }
                    const newsw = merge(sw, newSWObj)

                    dispatch(updateSignedWaivers(newsw))

                }
            }

        } else {
            const newsw = merge(sw, swObj)

            dispatch(updateSignedWaivers(newsw))
        }
    }



}



export const updateSignedWaivers = sw => (dispatch, getState) => {

    const waiverId = sw.Waiver_Id
    const pending = getState().pendingSW
    const recent = getState().recentSW

    if (not(isEmpty(pending))) {
        const waivers = clone(pending)
        const waiver = find(propEq("Waiver_Id", waiverId), waivers)
        const sws = waiver.SignedWaivers
        const wIndex = findIndex(propEq("Waiver_Id", waiverId))(waivers)
        const id = sw.SignedWaiver_Id
        const sIndex = findIndex(propEq("SignedWaiver_Id", id), sws)
        const s = find(propEq("SignedWaiver_Id", id), sws)
        if (not(isNil(s)) && not(equals(s, sw))) {
            const swsNew = Object.assign([], sws, { [sIndex]: sw })
            const waiverNew = Object.assign({}, waiver, { SignedWaivers: swsNew })
            const newList = Object.assign([], waivers, { [wIndex]: waiverNew })
            dispatch({ type: PENDING_WAIVERS_FETCHED, payload: newList })
        }
    }
    if (not(isEmpty(recent))) {
        const waivers = clone(recent)
        const waiver = find(propEq("Waiver_Id", waiverId), waivers)
        const sws = waiver.SignedWaivers
        const wIndex = findIndex(propEq("Waiver_Id", waiverId))(waivers)
        const id = sw.SignedWaiver_Id
        const sIndex = findIndex(propEq("SignedWaiver_Id", id), sws)
        const s = find(propEq("SignedWaiver_Id", id), sws)
        if (not(isNil(s)) && not(equals(s, sw))) {
            const swsNew = Object.assign([], sws, { [sIndex]: sw })
            const waiverNew = Object.assign({}, waiver, { SignedWaivers: swsNew })
            const newList = Object.assign([], waivers, { [wIndex]: waiverNew })
            dispatch({ type: RECENTLY_SIGNED_WAIVERS_FETCHED, payload: newList })
        }
    }


}
const applyNewSW = s => (dispatch, getState) => {
    const waiverId = s.Waiver_Id
    const n = s.SignedWaivers[0]
    const ps = map(p => Object.assign({}, p, { ActivityDates: [] }), n.Participants)
    const sw = Object.assign({}, n, { Waiver_Id: waiverId, Participants: ps })
    const pending = getState().pendingSW
    const recent = getState().recentSW

    if (not(isEmpty(pending))) {
        const waivers = clone(pending)
        const waiver = find(propEq("Waiver_Id", waiverId), waivers)
        const sws = waiver.SignedWaivers
        if (!contains(sw, sws)) {
            const wIndex = findIndex(propEq("Waiver_Id", waiverId))(waivers)
            const swsNew = append(sw, sws)
            const waiverNew = Object.assign({}, waiver, { SignedWaivers: swsNew })
            const newList = Object.assign([], waivers, { [wIndex]: waiverNew })
            dispatch({ type: PENDING_WAIVERS_FETCHED, payload: newList })
        }
    }
    if (not(isEmpty(recent))) {
        const waivers = clone(recent)
        const waiver = find(propEq("Waiver_Id", waiverId), waivers)
        const sws = waiver.SignedWaivers
        if (!contains(sw, sws)) {
            const wIndex = findIndex(propEq("Waiver_Id", waiverId))(waivers)
            const swsNew = append(sw, sws)
            const waiverNew = Object.assign({}, waiver, { SignedWaivers: swsNew })
            const newList = Object.assign([], waivers, { [wIndex]: waiverNew })

            dispatch({ type: RECENTLY_SIGNED_WAIVERS_FETCHED, payload: newList })
        }
    }
}